import { createReducer } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

import { PairInfo, SUPPORTED_PAIRS } from 'constants/pairs';
import { Pool } from 'types';

import {
  setPair,
  setPoolData,
  setBalance,
  setUserBalanceHistory,
  setHistoryIsFetch,
  setProgress,
  clearProgress,
  clearBalances,
  clearUserBalanceHistory,
  clearHistoryIsFetch,
} from './actions';

export interface Balance {
  baseCurrencyKey: string;
  quoteCurrencyKey: string;
  balance: BigNumber;
  balance1?: BigNumber;
}

export interface UserPoolBalance {
  baseCurrencyKey: string;
  quoteCurrencyKey: string;
  balance: BigNumber;
}

export interface UserBalancesHistory {
  [day: string] : {
    [poolId: string]: UserPoolBalance;
  };
}

export interface PoolState {
  selectedPair: PairInfo;

  pools: {
    [poolId: string]: Pool;
  };

  balances: {
    [poolId: string]: Balance;
  };

  userBalanceHistory:{
    [day: string] : {
      [poolId: string]: UserPoolBalance;
    };
  }
  progress: number;
  userBalanceHistoryFetch: boolean;
}


const initialState: PoolState = {
  selectedPair: SUPPORTED_PAIRS[0],
  pools: {},
  balances: {},
  userBalanceHistory: {},
  progress: 0,
  userBalanceHistoryFetch: false,
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setPair, (state, { payload }) => {
      state.selectedPair = payload;
    })
    .addCase(setPoolData, (state, { payload }) => {
      const { poolId, poolData } = payload;
      state.pools = {
        ...state.pools,
        [poolId]: {
          ...state.pools[poolId],
          ...poolData,
        },
      };
    })
    .addCase(setBalance, (state, { payload }) => {
      const { poolId, balance } = payload;
      state.balances = {
        ...state.balances,
        [poolId]: balance,
      };
    })
    .addCase(setProgress, (state, { payload }) => {
      const {day} = payload;
      state.progress = state.progress+1;
    })
    .addCase(setHistoryIsFetch, (state, { payload }) => {
      state.userBalanceHistoryFetch = true;
    })
    .addCase(setUserBalanceHistory, (state, { payload }) => {
      const {x,day} = payload;
      state.userBalanceHistory = {
        ...state.userBalanceHistory,
        [day]: x[day],
      };
    })
    .addCase(clearProgress, (state, { payload }) => {
      state.progress = 0;
    })
     .addCase(clearUserBalanceHistory, (state, { payload }) => {
      state.userBalanceHistory = {};
    })
    .addCase(clearBalances, (state, { payload }) => {
      state.balances = {};
    })
    .addCase(clearHistoryIsFetch, (state, { payload }) => {
      state.userBalanceHistoryFetch = false;
    })
);

import React, { useState } from 'react';

// import createHistory from 'history/createBrowserHistory'
import { AppReactContext, SupplyReactContext, ThemeReactContext, InIcxReactContext } from 'packages/application';
import { IconReactProvider } from 'packages/icon-react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import { initGA, GApageView } from 'app/components/GoogleAnalytics';
import NotificationContainer from 'app/components/Notification/NotificationContainer';
import WalletModal from 'app/components/WalletModal';
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from 'app/theme';
import TransactionUpdater from 'store/transactions/updater';

import { ExplorePoolsPage } from './containers/ExplorePoolsPage/Loadable';
// import { HomePage } from './containers/HomePage/Loadable';
import { InsightPage } from './containers/InsightPage/Loadable';
import { PortfolioPage } from './containers/PortfolioPage/Loadable';
import { YieldPlannerPage } from './containers/YieldPlannerPage/Loadable';

function Updaters() {
  return (
    <>
      <TransactionUpdater />
    </>
  );
}

export function App() {
  const { i18n } = useTranslation();
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const value = { isSideBarOpen, setSideBarOpen };

  const [lightMode, setLightMode] = useState(false);
  const ligthModeValue = { lightMode, setLightMode };

  const [inICX, setInICX] = useState(false);
  const inIcxValue = { inICX, setInICX };

  const [supply, setSupply] = useState({});
  const supplyValue = { supply, setSupply };

  React.useEffect(() => {
    initGA();
  }, []);

  return (
    <>
      <FixedGlobalStyle />
      <IconReactProvider>
        <AppReactContext.Provider value={value}>
          <SupplyReactContext.Provider value={supplyValue}>
            <ThemeReactContext.Provider value={ligthModeValue}>
              <InIcxReactContext.Provider value={inIcxValue}>
                <Updaters />

                <ThemeProvider>
                  <ThemedGlobalStyle />
                  <NotificationContainer />
                  <WalletModal />

                  <BrowserRouter>
                    <Helmet
                      titleTemplate="%s | Foligator"
                      defaultTitle="Foligator | Track your DeFi returns"
                      htmlAttributes={{ lang: i18n.language }}
                    />

                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/portfolio" />
                      </Route>
                      <Route path="/portfolio" component={PortfolioPage} />
                      <Route path="/insights" component={InsightPage} />
                      <Route path="/explorepools" component={ExplorePoolsPage} />
                      <Route path="/yieldplanner" component={YieldPlannerPage} />
                      {/* <Route path="/home" component={HomePage} /> */}
                      <Route
                        component={() => {
                          window.location.href = 'https://foligator.io/404';
                          return null;
                        }}
                      />
                    </Switch>
                  </BrowserRouter>
                </ThemeProvider>
              </InIcxReactContext.Provider>
            </ThemeReactContext.Provider>
          </SupplyReactContext.Provider>
        </AppReactContext.Provider>
      </IconReactProvider>
    </>
  );
}

// export const initGA = () => {
//   ReactGA.initialize('G-6PXP8JT52T');
// }

// export const GApageView = (page) => {
//   ReactGA.pageview(page);
// }

import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-171630741-3'); // put your tracking id here
};

export const GApageView = page => {
  ReactGA.pageview(page);
};

export const GAevent = (categoryName, eventName, labelName, value) => {
  ReactGA.event({
    category: categoryName, // Required
    action: eventName, // Required
    label: labelName,
    value: value,
    nonInteraction: false,
  });
};

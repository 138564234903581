import { createAction } from '@reduxjs/toolkit';

import { PairInfo } from 'constants/pairs';
import { Pool } from 'types';

import { Balance, UserPoolBalance } from './reducer';

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const setPair = createAction<PairInfo>('pool/setPair');
export const setHistoryIsFetch = createAction('pool/setHistoryIsFetch');

export const setPoolData = createAction<{ poolId: number; poolData: Partial<Pool> }>('pool/setPoolData');

export const setBalance = createAction<{ poolId: number; balance: Balance }>('pool/setBalance');

export const setProgress = createAction<{ day: number }>('pool/setProgress');

export const setUserBalanceHistory = createAction<{
  x: {
    [day: string]: {
      [poolId: string]: { userPoolBalance: UserPoolBalance };
    };
  };
  day: number;
}>('pool/setUserBalanceHistory');

export const clearProgress = createAction('pool/clearProgress');
export const clearUserBalanceHistory = createAction('pool/clearUserBalanceHistory');
export const clearBalances = createAction('pool/clearBalances');
export const clearHistoryIsFetch = createAction('pool/clearHistoryIsFetch');

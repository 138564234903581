import keyBy from 'lodash/keyBy';
import { SupportedChainId as NetworkId } from 'packages/BalancedJs';

import { ReactComponent as BALNIcon } from 'assets/tokens/BALN.svg';
import { ReactComponent as bnUSDIcon } from 'assets/tokens/bnUSD.svg';
import { ReactComponent as CFTIcon } from 'assets/tokens/CFT.svg';
import { ReactComponent as FINIcon } from 'assets/tokens/FIN.svg';
import { ReactComponent as GBETIcon } from 'assets/tokens/GBET.svg';
import { ReactComponent as ICXIcon } from 'assets/tokens/ICX.svg';
import { ReactComponent as IUSDCIcon } from 'assets/tokens/IUSDC.svg';
import { ReactComponent as IUSDTIcon } from 'assets/tokens/IUSDT.svg';
import { ReactComponent as METXIcon } from 'assets/tokens/METX.svg';
import { ReactComponent as OMMIcon } from 'assets/tokens/OMM.svg';
import { ReactComponent as sICXIcon } from 'assets/tokens/sICX.svg';
import { ReactComponent as USDSIcon } from 'assets/tokens/USDS.svg';
import { CurrencyKey, Pool } from 'types';
import { Currency } from 'types/balanced-sdk-core';

import { PairInfo, SUPPORTED_PAIRS } from './pairs';

export const CURRENCY_STR = ['ICX', 'sICX', 'bnUSD', 'BALN', 'IUSDC', 'OMM', 'USDS', 'CFT', 'METX', 'IUSDT', 'GBET','FIN'];

export const CURRENCY_MAP = keyBy(CURRENCY_STR);

export const currencyKeyToIconMap = {
  ICX: ICXIcon,
  sICX: sICXIcon,
  bnUSD: bnUSDIcon,
  BALN: BALNIcon,
  OMM: OMMIcon,
  IUSDC: IUSDCIcon,
  USDS: USDSIcon,
  CFT: CFTIcon,
  METX: METXIcon,
  IUSDT: IUSDTIcon,
  GBET: GBETIcon,
  FIN: FINIcon,
};


export const getTradePair = (
  baseKey?: CurrencyKey,
  quoteKey?: CurrencyKey,
): [PairInfo | undefined, boolean | undefined] => {
  if (baseKey && quoteKey) {
    const pair1 = SUPPORTED_PAIRS.find(pair => pair.baseCurrencyKey === baseKey && pair.quoteCurrencyKey === quoteKey);
    const pair2 = SUPPORTED_PAIRS.find(pair => pair.baseCurrencyKey === quoteKey && pair.quoteCurrencyKey === baseKey);

    if (pair1) {
      return [pair1, false];
    } else if (pair2) {
      return [pair2, true];
    }
  }
  return [undefined, undefined];
};

export const isQueue = (t: Pool | PairInfo) => {
  if (
    (t.baseCurrencyKey === 'sICX' && t.quoteCurrencyKey === 'ICX') ||
    (t.baseCurrencyKey === 'ICX' && t.quoteCurrencyKey === 'sICX')
  )
    return true;
  return false;
};

export const canBeQueue = (inputCurrency?: Currency, outputCurrency?: Currency) => {
  if (
    (inputCurrency?.symbol === 'sICX' && outputCurrency?.symbol === 'ICX') ||
    (inputCurrency?.symbol === 'ICX' && outputCurrency?.symbol === 'sICX')
  )
    return true;
  return false;
};

export const addressToCurrencyKeyMap = {
  [NetworkId.MAINNET]: {
    cx2609b924e33ef00b648a409245c7ea394c467824: 'sICX',
    cx88fd7df7ddff82f7cc735c871dc519838cb235bb: 'bnUSD',
    cxf61cd5a45dc9f91c15aa65831a30a90d59a09619: 'BALN',
    cx0000000000000000000000000000000000000000: 'ICX',
    cxae3034235540b924dfcc1b45836c293dcc82bfb7: 'IUSDC',
    cxbb2871f468a3008f80b08fdde5b8b951583acf06: 'USDS',
    cx1a29259a59f463a67bb2ef84398b30ca56b5830a: 'OMM',
    cx2e6d0fc0eca04965d06038c8406093337f085fcf: 'CFT',
    cx369a5f4ce4f4648dfc96ba0c8229be0693b4eca2: 'METX',
    cx3a36ea1f6b9aa3d2dd9cb68e8987bcc3aabaaa88: 'IUSDT',
    cx6139a27c15f1653471ffba0b4b88dc15de7e3267: 'GBET',
    cx785d504f44b5d2c8dac04c5a1ecd75f18ee57d16: 'FIN',
  },
  [NetworkId.YEOUIDO]: {
    cxae6334850f13dfd8b50f8544d5acb126bb8ef82d: 'sICX',
    cxc48c9c81ceef04445c961c5cc8ff056d733dfe3a: 'bnUSD',
    cx36169736b39f59bf19e8950f6c8fa4bfa18b710a: 'BALN',
    cx0000000000000000000000000000000000000000: 'ICX',
    cx65f639254090820361da483df233f6d0e69af9b7: 'IUSDC',
    cxc0666df567a6e0b49342648e98ccbe5362b264ea: 'USDS',
    cxc58f32a437c8e5a5fcb8129626662f2252ad2678: 'OMM',
    cxf7313d7fd611c99b8db29e298699be4b1fd86661: 'CFT',
  },
  [NetworkId.SEJONG]: {
    cx70806fdfa274fe12ab61f1f98c5a7a1409a0c108: 'sICX',
    cx5838cb516d6156a060f90e9a3de92381331ff024: 'bnUSD',
    cx303470dbc10e5b4ab8831a61dbe00f75db10c38b: 'BALN',
    cx0000000000000000000000000000000000000000: 'ICX',
  },
};


export const currencyKeyToAddressMap = {
  [NetworkId.MAINNET]: {
    sICX: 'cx2609b924e33ef00b648a409245c7ea394c467824',
    bnUSD: 'cx88fd7df7ddff82f7cc735c871dc519838cb235bb',
    BALN: 'cxf61cd5a45dc9f91c15aa65831a30a90d59a09619',
    ICX: 'cx0000000000000000000000000000000000000000',
    IUSDC: 'cxae3034235540b924dfcc1b45836c293dcc82bfb7',
    USDS: 'cxbb2871f468a3008f80b08fdde5b8b951583acf06',
    OMM: 'cx1a29259a59f463a67bb2ef84398b30ca56b5830a',
    CFT: 'cx2e6d0fc0eca04965d06038c8406093337f085fcf',
    METX: 'cx369a5f4ce4f4648dfc96ba0c8229be0693b4eca2',
    IUSDT: 'cx3a36ea1f6b9aa3d2dd9cb68e8987bcc3aabaaa88',
    GBET: 'cx6139a27c15f1653471ffba0b4b88dc15de7e3267',
    FIN: 'cx785d504f44b5d2c8dac04c5a1ecd75f18ee57d16',
  },
  [NetworkId.YEOUIDO]: {
    sICX: 'cxae6334850f13dfd8b50f8544d5acb126bb8ef82d',
    bnUSD: 'cxc48c9c81ceef04445c961c5cc8ff056d733dfe3a',
    BALN: 'cx36169736b39f59bf19e8950f6c8fa4bfa18b710a',
    ICX: 'cx0000000000000000000000000000000000000000',
    IUSDC: 'cx65f639254090820361da483df233f6d0e69af9b7',
    USDS: 'cxc0666df567a6e0b49342648e98ccbe5362b264ea',
    OMM: 'cx05515d126a47a98c682fa86992329e6c2ec70503',
    CFT: 'cxf7313d7fd611c99b8db29e298699be4b1fd86661',
  },
};
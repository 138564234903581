import keyBy from 'lodash/keyBy';
import { SupportedChainId as NetworkId } from 'packages/BalancedJs';

import {
  sICX,
  ICX,
  bnUSD,
  BALN,
  IUSDC,
  USDS,
  OMM,
  CFT,
  METX,
  IUSDT,
  GBET,
  FIN,
  sICX_YEOUIDO,
  ICX_YEOUIDO,
  bnUSD_YEOUIDO,
  BALN_YEOUIDO,
  IUSDC_YEOUIDO,
  USDS_YEOUIDO,
  OMM_YEOUIDO,
  CFT_YEOUIDO,
  sICX_SEJONG,
  ICX_SEJONG,
  bnUSD_SEJONG,
  BALN_SEJONG,
  IUSDC_SEJONG,
} from 'constants/tokens';
import { Token } from 'types/balanced-sdk-core';

import { NETWORK_ID } from './config';

export interface PairInfo {
  readonly chainId: number;
  readonly id: number;
  readonly name: string;
  readonly baseCurrencyKey: string;
  readonly quoteCurrencyKey: string;
  readonly rewards?: number;
  readonly baseToken: Token;
  readonly quoteToken: Token;
}

export type CurrencyKey = string;

export interface Pair {
  baseCurrencyKey: CurrencyKey;
  quoteCurrencyKey: CurrencyKey;
  pair: string;
  poolId: number;
  name: string;
  rewards?: number;
}

// this information contains the pairs the balanced supports
// eventually this information will saved in json file.

export const SUPPORTED_PAIRS_INFO: { [networkId: number]: PairInfo[] } = {
  [NetworkId.MAINNET]: [
    {
      chainId: 1,
      id: 1,
      name: 'sICX/ICX',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'ICX',
      rewards: 0.03,
      baseToken: sICX,
      quoteToken: ICX,
    },
    {
      chainId: 1,
      id: 2,
      name: 'sICX/bnUSD',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'bnUSD',
      baseToken: sICX,
      quoteToken: bnUSD,
      rewards: 0.12,
    },
    {
      chainId: 1,
      id: 3,
      name: 'BALN/bnUSD',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'bnUSD',
      baseToken: BALN,
      quoteToken: bnUSD,
      rewards: 0.12,
    },
    {
      chainId: 1,
      id: 4,
      name: 'BALN/sICX',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'sICX',
      baseToken: BALN,
      quoteToken: sICX,
      rewards: 0.15,
    },
    {
      chainId: 1,
      id: 5,
      name: 'IUSDC/bnUSD',
      baseCurrencyKey: 'IUSDC',
      quoteCurrencyKey: 'bnUSD',
      baseToken: IUSDC,
      quoteToken: bnUSD,
      rewards: 0.025,
    },
    {
      chainId: 1,
      id: 15,
      name: 'IUSDT/bnUSD',
      baseCurrencyKey: 'IUSDT',
      quoteCurrencyKey: 'bnUSD',
      baseToken: IUSDT,
      quoteToken: bnUSD,
      rewards: 0.005,
    },
    {
      chainId: 1,
      id: 10,
      name: 'USDS/bnUSD',
      baseCurrencyKey: 'USDS',
      quoteCurrencyKey: 'bnUSD',
      baseToken: USDS,
      quoteToken: bnUSD,
      rewards: 0.02,
    },
    {
      chainId: 1,
      id: 7,
      name: 'OMM/sICX',
      baseCurrencyKey: 'OMM',
      quoteCurrencyKey: 'sICX',
      baseToken: OMM,
      quoteToken: sICX,
    },
    {
      chainId: 1,
      id: 6,
      name: 'OMM/IUSDC',
      baseCurrencyKey: 'OMM',
      quoteCurrencyKey: 'IUSDC',
      baseToken: OMM,
      quoteToken: IUSDC,
    },
    {
      chainId: 1,
      id: 8,
      name: 'OMM/USDS',
      baseCurrencyKey: 'OMM',
      quoteCurrencyKey: 'USDS',
      baseToken: OMM,
      quoteToken: USDS,
    },
    {
      chainId: 1,
      id: 9,
      name: 'CFT/sICX',
      baseCurrencyKey: 'CFT',
      quoteCurrencyKey: 'sICX',
      baseToken: CFT,
      quoteToken: sICX,
    },
    {
      chainId: 1,
      id: 11,
      name: 'METX/bnUSD',
      baseCurrencyKey: 'METX',
      quoteCurrencyKey: 'bnUSD',
      baseToken: METX,
      quoteToken: bnUSD,
    },
    {
      chainId: 1,
      id: 12,
      name: 'METX/sICX',
      baseCurrencyKey: 'METX',
      quoteCurrencyKey: 'sICX',
      baseToken: METX,
      quoteToken: sICX,
    },
    {
      chainId: 1,
      id: 13,
      name: 'METX/IUSDC',
      baseCurrencyKey: 'METX',
      quoteCurrencyKey: 'IUSDC',
      baseToken: METX,
      quoteToken: IUSDC,
    },
    {
      chainId: 1,
      id: 14,
      name: 'METX/USDS',
      baseCurrencyKey: 'METX',
      quoteCurrencyKey: 'USDS',
      baseToken: METX,
      quoteToken: USDS,
    },
    {
      chainId: 1,
      id: 17,
      name: 'GBET/bnUSD',
      baseCurrencyKey: 'GBET',
      quoteCurrencyKey: 'bnUSD',
      baseToken: GBET,
      quoteToken: bnUSD,
    },
    {
      chainId: 1,
      id: 31,
      name: 'FIN/bnUSD',
      baseCurrencyKey: 'FIN',
      quoteCurrencyKey: 'bnUSD',
      baseToken: FIN,
      quoteToken: bnUSD,
    },
  ],
  [NetworkId.YEOUIDO]: [
    {
      chainId: 3,
      id: 1,
      name: 'sICX/ICX',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'ICX',
      rewards: 0.1,
      baseToken: sICX_YEOUIDO,
      quoteToken: ICX_YEOUIDO,
    },
    {
      chainId: 3,
      id: 2,
      name: 'sICX/bnUSD',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'bnUSD',
      baseToken: sICX_YEOUIDO,
      quoteToken: bnUSD_YEOUIDO,
      rewards: 0.175,
    },
    {
      chainId: 3,
      id: 3,
      name: 'BALN/bnUSD',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'bnUSD',
      baseToken: BALN_YEOUIDO,
      quoteToken: bnUSD_YEOUIDO,
      rewards: 0.175,
    },
    {
      chainId: 3,
      id: 4,
      name: 'BALN/sICX',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'sICX',
      baseToken: BALN_YEOUIDO,
      quoteToken: sICX_YEOUIDO,
      rewards: 0.05,
    },
    {
      chainId: 3,
      id: 24,
      name: 'OMM/IUSDC',
      baseCurrencyKey: 'OMM',
      quoteCurrencyKey: 'IUSDC',
      baseToken: OMM_YEOUIDO,
      quoteToken: IUSDC_YEOUIDO,
    },
    {
      chainId: 3,
      id: 25,
      name: 'OMM/sICX',
      baseCurrencyKey: 'OMM',
      quoteCurrencyKey: 'sICX',
      baseToken: OMM_YEOUIDO,
      quoteToken: sICX_YEOUIDO,
    },
    {
      chainId: 3,
      id: 23,
      name: 'OMM/USDS',
      baseCurrencyKey: 'OMM',
      quoteCurrencyKey: 'USDS',
      baseToken: OMM_YEOUIDO,
      quoteToken: USDS_YEOUIDO,
    },
    {
      chainId: 3,
      id: 30,
      name: 'CFT/sICX',
      baseCurrencyKey: 'CFT',
      quoteCurrencyKey: 'sICX',
      baseToken: CFT_YEOUIDO,
      quoteToken: sICX_YEOUIDO,
    },
  ],
  [NetworkId.SEJONG]: [
    {
      chainId: 83,
      id: 1,
      name: 'sICX/ICX',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'ICX',
      baseToken: sICX_SEJONG,
      quoteToken: ICX_SEJONG,
      rewards: 0.07,
    },
    {
      chainId: 83,
      id: 2,
      name: 'sICX/bnUSD',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'bnUSD',
      baseToken: sICX_SEJONG,
      quoteToken: bnUSD_SEJONG,
      rewards: 0.175,
    },
    {
      chainId: 83,
      id: 3,
      name: 'BALN/bnUSD',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'bnUSD',
      baseToken: BALN_SEJONG,
      quoteToken: bnUSD_SEJONG,
      rewards: 0.175,
    },
    {
      chainId: 83,
      id: 4,
      name: 'BALN/sICX',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'sICX',
      baseToken: BALN_SEJONG,
      quoteToken: sICX_SEJONG,
      rewards: 0.05,
    },
    {
      chainId: 83,
      id: 5,
      name: 'IUSDC/bnUSD',
      baseCurrencyKey: 'IUSDC',
      quoteCurrencyKey: 'bnUSD',
      baseToken: IUSDC_SEJONG,
      quoteToken: bnUSD_SEJONG,
      rewards: 0.005,
    },
  ],
};

export const SUPPORTED_PAIRS = SUPPORTED_PAIRS_INFO[NETWORK_ID];

export const SUPPORTED_LPAIRS_INFO: { [networkId: number]: PairInfo[] } = {
  [NetworkId.MAINNET]: [
    {
      chainId: 1,
      id: 1,
      name: 'sICX/ICX',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'ICX',
      rewards: 0.03,
      baseToken: sICX,
      quoteToken: ICX,
    },
    {
      chainId: 1,
      id: 2,
      name: 'sICX/bnUSD',
      baseCurrencyKey: 'sICX',
      quoteCurrencyKey: 'bnUSD',
      baseToken: sICX,
      quoteToken: bnUSD,
      rewards: 0.12,
    },
    {
      chainId: 1,
      id: 3,
      name: 'BALN/bnUSD',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'bnUSD',
      baseToken: BALN,
      quoteToken: bnUSD,
      rewards: 0.12,
    },
    {
      chainId: 1,
      id: 4,
      name: 'BALN/sICX',
      baseCurrencyKey: 'BALN',
      quoteCurrencyKey: 'sICX',
      baseToken: BALN,
      quoteToken: sICX,
      rewards: 0.15,
    },
    {
      chainId: 1,
      id: 5,
      name: 'IUSDC/bnUSD',
      baseCurrencyKey: 'IUSDC',
      quoteCurrencyKey: 'bnUSD',
      baseToken: IUSDC,
      quoteToken: bnUSD,
      rewards: 0.025,
    },
    {
      chainId: 1,
      id: 10,
      name: 'USDS/bnUSD',
      baseCurrencyKey: 'USDS',
      quoteCurrencyKey: 'bnUSD',
      baseToken: USDS,
      quoteToken: bnUSD,
      rewards: 0.02,
    },
    {
      chainId: 1,
      id: 15,
      name: 'IUSDT/bnUSD',
      baseCurrencyKey: 'IUSDT',
      quoteCurrencyKey: 'bnUSD',
      baseToken: IUSDT,
      quoteToken: bnUSD,
      rewards: 0.005,
    },
  ],
  
};
export const SUPPORTED_LPAIRS = SUPPORTED_LPAIRS_INFO[NETWORK_ID];

// for pools explorer

export const CURRENCY = ['ICX', 'sICX', 'bnUSD', 'BALN', 'IUSDC', 'OMM', 'USDS', 'CFT', 'METX', 'IUSDT', 'GBET', 'FIN'];

export const CURRENCY_MAP = keyBy(CURRENCY);

export const toMarketPair = (baseCurrencyKey: CurrencyKey, quoteCurrencyKey: string) =>
  `${baseCurrencyKey} / ${quoteCurrencyKey}`;

export const toMarketName = (baseCurrencyKey: CurrencyKey, quoteCurrencyKey: string) =>
  `${baseCurrencyKey}/${quoteCurrencyKey}`;

  export const SUPPORTED_POOLPAIRS: Array<Pair> = [
    {
      baseCurrencyKey: CURRENCY_MAP['sICX'],
      quoteCurrencyKey: CURRENCY_MAP['ICX'],
      pair: toMarketPair(CURRENCY_MAP['sICX'], CURRENCY_MAP['ICX']),
      poolId: 1,
      name: toMarketName(CURRENCY_MAP['sICX'], CURRENCY_MAP['ICX']),
      rewards: 0.03,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['sICX'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['sICX'], CURRENCY_MAP['bnUSD']),
      poolId: 2,
      name: toMarketName(CURRENCY_MAP['sICX'], CURRENCY_MAP['bnUSD']),
      rewards: 0.12,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['BALN'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['BALN'], CURRENCY_MAP['bnUSD']),
      poolId: 3,
      name: toMarketName(CURRENCY_MAP['BALN'], CURRENCY_MAP['bnUSD']),
      rewards: 0.12,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['BALN'],
      quoteCurrencyKey: CURRENCY_MAP['sICX'],
      pair: toMarketPair(CURRENCY_MAP['BALN'], CURRENCY_MAP['sICX']),
      poolId: 4,
      name: toMarketName(CURRENCY_MAP['BALN'], CURRENCY_MAP['sICX']),
      rewards: 0.15,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['IUSDC'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['IUSDC'], CURRENCY_MAP['bnUSD']),
      name: toMarketName(CURRENCY_MAP['IUSDC'], CURRENCY_MAP['bnUSD']),
      poolId: 5,
      rewards: 0.025,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['IUSDT'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['IUSDT'], CURRENCY_MAP['bnUSD']),
      name: toMarketName(CURRENCY_MAP['IUSDT'], CURRENCY_MAP['bnUSD']),
      poolId: 15,
      rewards: 0.005,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['USDS'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['USDS'], CURRENCY_MAP['bnUSD']),
      name: toMarketName(CURRENCY_MAP['USDS'], CURRENCY_MAP['bnUSD']),
      poolId: 10,
      rewards: 0.02,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['OMM'],
      quoteCurrencyKey: CURRENCY_MAP['IUSDC'],
      pair: toMarketPair(CURRENCY_MAP['OMM'], CURRENCY_MAP['IUSDC']),
      name: toMarketName(CURRENCY_MAP['OMM'], CURRENCY_MAP['IUSDC']),
      poolId: 6,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['OMM'],
      quoteCurrencyKey: CURRENCY_MAP['sICX'],
      pair: toMarketPair(CURRENCY_MAP['OMM'], CURRENCY_MAP['sICX']),
      name: toMarketName(CURRENCY_MAP['OMM'], CURRENCY_MAP['sICX']),
      poolId: 7,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['OMM'],
      quoteCurrencyKey: CURRENCY_MAP['USDS'],
      pair: toMarketPair(CURRENCY_MAP['OMM'], CURRENCY_MAP['USDS']),
      name: toMarketName(CURRENCY_MAP['OMM'], CURRENCY_MAP['USDS']),
      poolId: 8,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['CFT'],
      quoteCurrencyKey: CURRENCY_MAP['sICX'],
      pair: toMarketPair(CURRENCY_MAP['CFT'], CURRENCY_MAP['sICX']),
      name: toMarketName(CURRENCY_MAP['CFT'], CURRENCY_MAP['sICX']),
      poolId: 9,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['METX'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['METX'], CURRENCY_MAP['bnUSD']),
      name: toMarketName(CURRENCY_MAP['METX'], CURRENCY_MAP['bnUSD']),
      poolId: 11,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['METX'],
      quoteCurrencyKey: CURRENCY_MAP['sICX'],
      pair: toMarketPair(CURRENCY_MAP['METX'], CURRENCY_MAP['sICX']),
      name: toMarketName(CURRENCY_MAP['METX'], CURRENCY_MAP['sICX']),
      poolId: 12,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['METX'],
      quoteCurrencyKey: CURRENCY_MAP['IUSDC'],
      pair: toMarketPair(CURRENCY_MAP['METX'], CURRENCY_MAP['IUSDC']),
      name: toMarketName(CURRENCY_MAP['METX'], CURRENCY_MAP['IUSDC']),
      poolId: 13,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['METX'],
      quoteCurrencyKey: CURRENCY_MAP['USDS'],
      pair: toMarketPair(CURRENCY_MAP['METX'], CURRENCY_MAP['USDS']),
      name: toMarketName(CURRENCY_MAP['METX'], CURRENCY_MAP['USDS']),
      poolId: 14,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['GBET'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['GBET'], CURRENCY_MAP['bnUSD']),
      name: toMarketName(CURRENCY_MAP['GBET'], CURRENCY_MAP['bnUSD']),
      poolId: 17,
    },
    {
      baseCurrencyKey: CURRENCY_MAP['FIN'],
      quoteCurrencyKey: CURRENCY_MAP['bnUSD'],
      pair: toMarketPair(CURRENCY_MAP['FIN'], CURRENCY_MAP['bnUSD']),
      name: toMarketName(CURRENCY_MAP['FIN'], CURRENCY_MAP['bnUSD']),
      poolId: 31,
    },
  ];
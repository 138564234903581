import React from 'react';

interface AppContextInterface {
  isSideBarOpen: boolean;
  setSideBarOpen: any;
}

export const AppReactContext = React.createContext<AppContextInterface>({
  isSideBarOpen: false,
  setSideBarOpen: () => {},
});

interface SupplyContextInterface {
  supply: { [key: string]: { hash: string; time: string; ICX: number } };
  setSupply: any;
}

export const SupplyReactContext = React.createContext<SupplyContextInterface>({
  supply: {},
  setSupply: () => {},
});

interface ThemeContextInterface {
  lightMode: boolean;
  setLightMode: any;
}

export const ThemeReactContext = React.createContext<ThemeContextInterface>({
  lightMode: false,
  setLightMode: () => {},
});

interface InIcxContextInterface {
  inICX: boolean;
  setInICX: any;
}

export const InIcxReactContext = React.createContext<InIcxContextInterface>({
  inICX: false,
  setInICX: () => {},
});
